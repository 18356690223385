






























import Vue from "vue";
import { VerifyEmail } from "@/interfaces/auth/verify_email";
import SubmitButton from "@/components/auth/SubmitButton.vue";
import { mapGetters, mapMutations } from "vuex";
import {
  GET_USER_DETAILS,
  RESET_PASS_USER_EMAIL,
  RESET_PASSWORD_PAYLOAD
} from "@/store/modules/auth/constants";
import router from "@/router";
export default Vue.extend({
  name: "TokenVerification",
  components: { SubmitButton },
  data(): VerifyEmail & { email: string } {
    return {
      decoration_img_src: require("@/assets/illustrations/email.svg"),
      verification_code: "",
      is_valid: false,
      email: ""
    };
  },
  async created() {
    const decode = atob(this.$route.params.code).split("_&_");
    if (decode.length === 2) {
      this.verification_code = decode[1];
      this.email = decode[0];
      await this.submit_form();
    }
  },
  computed: {
    ...mapGetters("auth", {
      get_user_details: GET_USER_DETAILS,
      get_reset_pass_email: RESET_PASS_USER_EMAIL
    })
  },
  watch: {
    verification_code(new_value: string) {
      this.is_valid = /^[0-9]{4}$/.test(new_value);
    }
  },
  methods: {
    ...mapMutations("auth", {
      set_reset_pass_payload: RESET_PASSWORD_PAYLOAD
    }),
    async submit_form() {
      if (this.verification_code && this.verification_code.length === 4) {
        this.set_reset_pass_payload({
          reset_token: this.verification_code,
          email: this.email || this.get_reset_pass_email
        });
        await router.push("/auth/reset-password/create-new-password");
      }
    }
  }
});
